
export default {
  name: 'LifetimePopup',
  data() {
    return {
      showLifetimePopup: true,
      currencyCode: 'USD',
      offerings: {
        PRO: {
          1: { product_key: '1m-pro', name: 'Monthly', terms: 1, actual_price: 36, offer_price: 29, price: 29, discount_percentage: 0, currency: 'USD' },
          3: { product_key: '3m-pro', name: 'Quarterly', terms: 3, actual_price: 21, offer_price: 15, price: 45, discount_percentage: 30, currency: 'USD' },
          12: { product_key: '12m-pro', name: 'Yearly', terms: 12, actual_price: 21, offer_price: 12, price: 144, discount_percentage: 43, currency: 'USD' },
          0: { product_key: 'lifetime-pro', name: 'Lifetime', terms: 0, actual_price: 249, offer_price: 249, price: 249, discount_percentage: 0, currency: 'USD' }
        }
      }
    }
  },
  mounted() {
    this.checkPopupVisibility()
  },
  methods: {
    closeLifetimePopup() {
      // this.$store.commit('setLifeTimeOfferShown', true);
      // Store the current timestamp in localStorage when the popup is closed
      const now = new Date().getTime()
      localStorage.setItem('lifetimePopupClosedAt', now)
      this.showLifetimePopup = false
    },
    checkPopupVisibility() {
      const lifetimePopupClosedAt = localStorage.getItem('lifetimePopupClosedAt')
      if (lifetimePopupClosedAt) {
        const now = new Date().getTime()
        const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day
        if (now - lifetimePopupClosedAt < oneDay) {
          // If less than a day has passed since the popup was closed, keep it hidden
          this.showLifetimePopup = false
        }
      }
    },
    subscribeStripePlan(dataset) {
      const term = parseInt(dataset.term)
      const entitlement = dataset.entitlement
      const product = this.offerings[entitlement][term]
      if (product.price === 0) {
        // this.showToaster("It's free!");
        return
      }

      const sessionID = this.createCheckoutSession(product)
      sessionID.then((sessionID) => {
        this.stripeCheckout(sessionID)
      })
    },
    async createCheckoutSession(product) {
      const context = this
      const productKey = product.product_key
      context.$nuxt.$loading.start()

      const url = `${process.env.main_API_URL}/stripe/create-checkout-session/`
      // form data with product key
      const data = new FormData()
      data.append('product_key', productKey)
      const currentURL = new URL(window.location.href)
      data.append('base_url', `${currentURL.origin}`)
      // coupon_id
      // get discount code from url params using router
      if (this.$route.query.discount && this.$route.query.discount.length > 0) {
        this.$store.commit('setDiscountCode', this.$route.query.discount)
        data.append('coupon_id', this.$route.query.discount)
      } else if (context.$store.state.discountCode) {
        data.append('coupon_id', context.$store.state.discountCode)
      } else if (context.discountCode) {
        data.append('coupon_id', context.discountCode)
      }

      // Special offer coupon ids for lifetime
      if (product.terms === 0) {
        data.append('coupon_id', 'LIFETIMEDISCOUNT')
      }

      if (context.currencyCode) { data.append('currency_code', context.currencyCode) }

      const response = await this.$axios.$post(url, data)
      context.$nuxt.$loading.finish()
      return response
    },
    async stripeCheckout(sessionID) {
      const stripe = await this.getStripe()
      await stripe.redirectToCheckout({
        sessionId: sessionID
      })
    }
  }
}
