import { render, staticRenderFns } from "./MagicSearch.vue?vue&type=template&id=ccc03dda"
import script from "./MagicSearch.vue?vue&type=script&lang=js"
export * from "./MagicSearch.vue?vue&type=script&lang=js"
import style0 from "./MagicSearch.vue?vue&type=style&index=0&id=ccc03dda&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MagicSearchTitle: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/MagicSearch/MagicSearchTitle.vue').default,AppPlatforms: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/AppPlatforms.vue').default,MainWebAppShotComponent: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/MainWebAppShotComponent.vue').default,MainAppShotComponent: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/MainAppShotComponent.vue').default,AppFlowAppShotComponent: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/AppFlowAppShotComponent.vue').default,InPagePricingComponent: require('/home/runner/work/AppShotsWebapp/AppShotsWebapp/components/pricing/InPagePricingComponent.vue').default})
