
export default {
    name: 'SearchInput',
    props: {
        query: {
            type: String,
            required: true
        },
        onInput: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            placeholders: [
                'What are you looking for?',
                'Try "Onboarding"',
                'Try "Uber Driver"',
                'Try "Scan"',
            ],
            currentPlaceholder: '',
            currentPlaceholderIndex: 0,
            currentCharIndex: 0,
            isDeleting: false,
            typingSpeed: 100,
            deletingSpeed: 50,
            pauseDuration: 2000,
            stopTyping: false,
            isTyping: false,
            userHasTyped: false,
            caretPosition: 0,
            blinkCaret: false,
        }
    },
    mounted() {
        this.$refs.searchField.focus();
        this.typePlaceholder()
    },
    methods: {
        changeToDefaultPlaceholder() {
            if (!this.query) {
                this.stopTyping = true;
                this.currentPlaceholder = 'Search for apps, flows, UI comps & screens';
                this.caretPosition = 0;
                this.blinkCaret = true;
            }
        },
        updateQuery(event) {
            this.$emit('update:query', event.target.value)
            this.userHasTyped = true
            if (event.target.value === '') {
                this.stopTyping = false
                this.userHasTyped = false
                this.typePlaceholder()
            } else {
                this.stopTyping = true
                this.currentPlaceholder = '';
            }
            this.onInput()
        },
        typePlaceholder() {
            if (this.stopTyping) return
            this.isTyping = true
            const currentPhrase = this.placeholders[this.currentPlaceholderIndex]

            if (this.isDeleting) {
                this.currentPlaceholder = currentPhrase.substring(0, this.currentCharIndex - 1)
                this.currentCharIndex--
            } else {
                this.currentPlaceholder = currentPhrase.substring(0, this.currentCharIndex + 1)
                this.currentCharIndex++
            }

            this.updateCaretPosition()

            let typingDelay = this.isDeleting ? this.deletingSpeed : this.typingSpeed

            if (!this.isDeleting && this.currentCharIndex === currentPhrase.length) {
                typingDelay = this.pauseDuration
                this.isDeleting = true
                this.isTyping = false
            } else if (this.isDeleting && this.currentCharIndex === 0) {
                this.isDeleting = false
                this.currentPlaceholderIndex = (this.currentPlaceholderIndex + 1) % this.placeholders.length
                this.isTyping = false
            }

            setTimeout(() => this.typePlaceholder(), typingDelay)
        },
        updateCaretPosition() {
            // Calculate the width of the text up to the current character
            const measurer = document.createElement('span')
            measurer.style.visibility = 'hidden'
            measurer.style.position = 'absolute'
            measurer.style.whiteSpace = 'pre'
            measurer.style.font = 'inherit'
            measurer.textContent = this.currentPlaceholder
            document.body.appendChild(measurer)
            this.caretPosition = measurer.offsetWidth
            document.body.removeChild(measurer)
        }
    },
}
